
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services';

const UnAuthGuard = ({ component }) => {
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkUser();
    }, [component]);

    const checkUser = () => {
        try {

            AuthService.getUser().then((user) => {
                if (!user) {
                    localStorage.removeItem("token")
                } else {
                    navigate(`/`);
                }
                setStatus(true);

            }).catch(err => {
                if (err === false) {
                    setStatus(true);
                    // navigate(`/login`);
                } else {
                    navigate(`/`);
                }

            })
        } catch (error) {
            console.log("Error", error);
            navigate(`/`);
        }
    }

    return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default UnAuthGuard;