import { signInWithPhoneNumber } from "firebase/auth";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { useEffect, useState } from "react";
import logo_path from "../images/logo-33.png";
import { useNavigate } from "react-router-dom";
import AuthService from "./services";


export default function LoginScreen(props) {

    props.set_login_screen(true);


    const [showOTPContainer, set_show_otpContainer] = useState(false);
    const [phone_number, setPhoneNumber] = useState("+91 ");
    const [timeout, setTimeOut] = useState(false);
    const [verifying, setVerifying] = useState(false);

    const auth = getAuth();
    const navigate = useNavigate();

    auth.languageCode = "en";
    const [otp, setOTP] = useState("");

    const evalOtp = () => {
        const inputs = document.getElementById("inputs");
        var children = inputs.children;
        var val = "";
        Array.prototype.slice.call(children).forEach(digit => {
            val = val + digit.value;
        })

        setOTP(val);
    }

    function startTimer(duration, display) {
        var timer = duration, minutes, seconds;
        setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            display.textContent = minutes + ":" + seconds;

            if (--timer < 0) {
                timer = 0;
                setTimeOut(true);
                clearInterval(this);
                return;
            }
        }, 1000);
    }

    useEffect(() => {
        console.log("OTP:", otp);
    }, [otp]);

    useEffect(() => {
        if (timeout) {
            // window.location.reload();
        }
    }, [timeout]);

    function verify() {

        if (otp.length == 6) {
            try {
                setVerifying(true);
                window.confirmationResult.confirm(otp).then((result) => {
                    // User signed in successfully.

                    if (!result) throw EvalError;

                    const user = result.user;
                    
                    setVerifying(false);
                    var tokenResponse = result._tokenResponse;
                    
                    AuthService.checkUserExists({ uid: tokenResponse.localId }).then(data => {
                        if (data && data.code == "USER_NOT_FOUND") {
                            localStorage.setItem("x-t_nu", true);
                            navigate(`/register`);
                        } else {
                            localStorage.removeItem("x-t_nu");
                            window.location.replace('/');
                        }
                    })
                    props.set_show_splash(false);
                    props.set_login_screen(false);


                    // if (user) {
                    //     props.set_show_splash(false);
                    //     props.set_login_screen(false);
                    //     navigate(`/register`);
                    // }


                }).catch((error) => {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    setVerifying(false);
                    props.set_toast_message("Please try again after sometime");
                    console.log(error);
                });
            } catch (err) { console.log(err) }
        } else {
            props.set_toast_message("Please enter a valid OTP");
        }
    }

    const validatePhoneNumber = (num) => {
        return num.slice(3,).trimEnd().trimStart().length == 10 && (Number(num.slice(3,).trimEnd().trimStart()) != NaN);
    }

    const formatPhoneNumber = (num) => {
        console.log("formatted number:", num.slice(0, 3) + " " + num.slice(3, 8) + " " + num.slice(8,));
        return num.slice(0, 3) + " " + num.slice(3, 8) + " " + num.slice(8,);
    }

    function signIn() {
        console.log(phone_number);
        try {
            if (validatePhoneNumber(phone_number)) {
                setVerifying(true);
                // if (localStorage.getItem("s_ot") == "true") return;
                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});

                signInWithPhoneNumber(auth, formatPhoneNumber(phone_number), window.recaptchaVerifier)
                    .then((confirmationResult) => {
                        // SMS sent. Prompt user to type the code from the message, then sign the
                        // user in with confirmationResult.confirm(code).
                        window.confirmationResult = confirmationResult;
                        set_show_otpContainer(true);
                        props.set_toast_message("OTP Sent");
                        setVerifying(false);

                    }).catch((error) => {
                        // Error; SMS not sent
                        props.set_toast_message("Please try again");
                        // setTimeOut(() => { window.location.reload() }, 1000);
                        // window.recaptchaVerifier.render().then(function (widgetId) {
                        //     grecaptcha.reset(widgetId);
                        // });
                        console.log(error);
                        // ...
                    });

            } else {
                props.set_toast_message("Please enter a valid phone number");
            }
        } catch (err) { console.log(err) };
    }


    // useEffect(() => {

    //     if (localStorage.getItem("s_ot") == "true") {
    //         set_show_otpContainer(true);
    //     }
    // }, []);

    // otp controls
    function setOTPControls() {
        const inputs = document.getElementById("inputs");
        if (!inputs) return;

        inputs.childNodes[0].focus();
        inputs.addEventListener("click", (e) => {
            for (var k in inputs.childNodes) {
                if (inputs.childNodes[k].value == "") {
                    inputs.childNodes[k].focus();
                    break;
                }
            }
        })
        inputs.addEventListener("input", function (e) {
            const target = e.target;
            console.log("Value:", target.value);
            const val = target.value;

            if (isNaN(val)) {
                target.value = "";
                return;
            }

            if (val != "") {
                const next = target.nextElementSibling;
                if (next) {
                    next.focus();
                }
            }
        });

        inputs.addEventListener("keyup", function (e) {
            const target = e.target;
            const key = e.key.toLowerCase();
            console.log(key);
            if (key == "backspace" || key == "delete") {
                target.value = "";
                const prev = target.previousElementSibling;
                if (prev) {
                    prev.focus();
                }
                return;
            }
        });
    }
    // otp controls end
    useEffect(() => {

        if (showOTPContainer) {
            setOTPControls();
            // startTimer(50, document.querySelector("#timer"));
        }

    }, [showOTPContainer]);

    return (<>

        {!props.is_splash_in_view &&
            <div className="login-screen-container">
                <img src={logo_path} />
                <h2>Let's log you in :)</h2>
                {!showOTPContainer && <>
                    <p>Please enter your mobile number to continue</p>
                    <div
                        style={{ marginTop: "15px" }}
                        id="recaptcha-container"
                    ></div><br />

                    {!verifying && <input value={phone_number} onChange={(e) => { if (e.target.value != "+91" && e.target.value.length<=14) setPhoneNumber(e.target.value) }} style={{ color: "#000", fontFamily: "monospace", fontWeight: "bolder", letterSpacing: "0.45cap", textAlign: "center", border: "1px solid #0e883e", width: "75%" }} type="text" className="lg-box-center" />}
                    {verifying && <input disabled value={phone_number} style={{ color: "#000", fontFamily: "monospace", fontWeight: "bolder", letterSpacing: "0.45cap", textAlign: "center", width: "75%" }} type="text" className="lg-box-center" />}
                    <br />
                    <p style={{ paddingLeft: 0, textAlign: "center" }}>You will receive an OTP on this number</p><br />
                    {!verifying && <button className="btn-dark" id="sign-in-button" onClick={() => signIn()}>Send OTP</button>}
                    {/* {verifying && <button className="btn-light" id="sign-in-button" >OTP Sent</button>} */}</>

                }
                <br />
                {showOTPContainer && <><div class="otp-container">
                    <br />
                    <div id="inputs" class="inputs">
                        <input onChange={() => { evalOtp() }} class="input" type="text"
                            inputmode="numeric" maxlength="1" />
                        <input onChange={() => { evalOtp() }} class="input" type="text"
                            inputmode="numeric" maxlength="1" />
                        <input onChange={() => { evalOtp() }} class="input" type="text"
                            inputmode="numeric" maxlength="1" />
                        <input onChange={() => { evalOtp() }} class="input" type="text"
                            inputmode="numeric" maxlength="1" />
                        <input onChange={() => { evalOtp() }} class="input" type="text"
                            inputmode="numeric" maxlength="1" />
                        <input onChange={() => { evalOtp() }} class="input" type="text"
                            inputmode="numeric" maxlength="1" />
                    </div>
                </div>
                    <br />
                    <span style={{ color: "black" }} id="timer"></span>
                    <br />
                    <p style={{ textAlign: "center", paddingLeft: 0 }}>Page will refresh once the timer ends</p><br />
                    {!verifying && <button style={{ width: "75%" }} className="btn-dark" onClick={() => verify()}>Verify</button>}
                    {verifying && <button style={{ width: "75%" }} className="btn-dark" onClick={() => verify()}>Verifying..</button>}
                    <br /><br />

                </>
                }
                <h3 className="md-font" style={{ fontSize:"0.85rem", color: "gray", opacity: "0.35", letterSpacing: "0.85cap" }}>KIRANA DIGITAL</h3>
            </div>}
    </>);
}