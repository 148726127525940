import { useEffect } from "react"


// Waiting Animatin/Information

export default function WaitingCard(props) {

    // Collapse quantity selector
    function collapseWaitingCard(e) {
        let elem = document.querySelector(".waiting-card");
        if (elem == null) return;
        elem.style.animationName = "hide";
        document.querySelector(".parent").classList.remove("shadow");

    }

    useEffect(() => {
        document.querySelector(".parent").classList.add("shadow");


    }, [])

    return (<>

        <div className="waiting-card flex-list-item column j-c-c a-i-c">
            {props.orderState == null && <h3>Sending your order</h3>}
            {props.orderState == "pushed" && <h3>Order sent successfully</h3>}
            {props.orderState == "confirmed" && <h3>Order confirmed</h3>}
            {props.orderState == "rejected" && <h3>Order not confirmed</h3>}
            {props.orderState == "error" && <h3>An error occurred</h3>}

            <br /><br />
            <button onClick={() => props.setCartProcess({ "waiting": false, "processing": false })} className="btn btn-dark">Cancel</button>
        </div>


    </>)

}