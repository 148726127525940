import { useEffect, useState } from "react";
import img_path from "../../images/logo-white.png";
import QuantitySelector from "./QuantitySelector";
import CartSkeleton from "../CartSkeleton";
import OrderService from "../services/OrderService";


export default function OrderSearchWidget(props) {

    // const [cart, setCart] = useState({});

    // current item details
    const [currentItem, setCurrentItem] = useState({});

    // current item metrics
    const [itemMetrics, setItemMetrics] = useState({});


    var item_metrics = {
        "item_id": props.item.item_id,
        "quantity": 1,
        "weight": 200,
        "unit": 1,
        "name": "props.item.name",
        "price": "props.item.price",
        "metadata": {}
    };

    const [completeAddAction, setCompleteAddActionComplete] = useState(false);

    const cartController = props.cart;

    function addToCartStarts(item) {

        setCurrentItem({
            "item_id": item.item_id,
            "name": item.name,
            "price": item.price,
            "quantity": 1,
            "weight": 200,
            "unit": 1,
            "metadata": item.metadata
        })

    }

    function editItem(item) {
        let toUpdate = cartController.getItemById(item.id);
        console.log("to update::", toUpdate);
        setItemMetrics(toUpdate);
        setCurrentItem(toUpdate);
    }

    function completeAddToCart() {
        console.log("Added to cart::", currentItem, itemMetrics);

        console.log(itemMetrics.quantity);

        setCurrentItem({
            ...currentItem,
            quantity: itemMetrics.quantity,
            weight: itemMetrics.weight,
            unit: itemMetrics.unit,
        });



    }

    // Remove product form cart
    function removeFromCart() {
        cartController.removeFromCart(currentItem);
        setCurrentItem({});
        props.set_toast_message("Item removed from cart");
    }

    // Check whether item exists in cart
    function isItemAdded(item) {
        // Checks if the item is already added to the cart
        return cartController.getCart.items.map(it => it.item_id).indexOf(item.item_id, 0) != -1;

    }

    // Collapse quantity selector
    function collapseQuantitySelector(e) {
        let elem = document.querySelector(".wrapper.master_");
        if (elem == null) return;
        elem.style.animationName = "hide";
        document.querySelector(".parent").classList.remove("shadow");
        setTimeout(() => { setCurrentItem({}); props.set_selected_product(null); }, 400);
    }

    // Looks for update in the current item state
    // if the add action is in process, it will add product to cart.
    useEffect(() => {
        // console.log("current", currentItem);
        // if(itemMetrics) completeAddToCart();

        if (completeAddAction) {
            if (!isItemAdded(itemMetrics)) {
                cartController.addToCart(itemMetrics);
                props.refreshCart();
                props.set_toast_message("Item added to cart");
            } else {
                console.log("updating item");
                var item = cartController.getItemById(itemMetrics.item_id);
                cartController.updateItem(itemMetrics);
                props.refreshCart();
                console.log(item.quantity, itemMetrics.quantity);
                if (item.quantity != itemMetrics.quantity) {
                    props.set_toast_message("Updated item");
                }
            }
            setCompleteAddActionComplete(false);
        }

        console.log("Current item:", currentItem);
    }, [currentItem]);

    useEffect(() => {
        item_metrics.item_id = props.item.item_id;
        item_metrics.name = props.item.name;
        item_metrics.price = props.item.price;
        item_metrics.quantity = props.item.quantity;
        item_metrics.metadata = props.item.metadata;
        setItemMetrics(item_metrics);

    }, []);

    useEffect(() => {
        setCurrentItem(props.item);
    }, [props.item])

    useEffect(() => {
        // console.log("current item metrics", itemMetrics);
        if (completeAddAction) {
            // completeAddToCart();
        }

    }, [itemMetrics]);


    // Query Search Call API ------------------------------------------
    useEffect(() => {

        OrderService.searchProduct(props.query).then((res) => { }, (res) => {
            console.log("Query search response::", res);
        });

        if (props.query.length > 3) {
            props.set_show_results(true);
        } else {
            props.set_show_results(false);
        }
    }, [props.query]);

    // detect the complete add to cart event from Quantity selector component
    useEffect(() => {
        if (completeAddAction) {
            // setCurrentItem({});
            console.log("Add action complete");
            // setCompleteAddActionComplete(false);
            completeAddToCart();
        }
    }, [completeAddAction]);

    useEffect(() => {
        document.querySelector(".parent").classList.add("shadow");
        document.querySelector(".parent.shadow").addEventListener("click", (e) => {

            if (e.target.classList.value == "parent shadow") { collapseQuantitySelector(); }
        })
    }, []);

    const item = props.item;

    return (
        <>
            <div className="order-search-widget" style={{ height: "fit-content !important" }}>
                <div className="order-search-results">
                    <ul style={{ background: "white" }}>
                        <li>
                            <div className="wrapper master_" onClick={() => { addToCartStarts(item) }} style={{ paddingBottom: "0" }}>
                                {/* display image */}

                                {/* order search details- meta info */}
                                <div className="order-search-details">
                                    <h4>{item.name}</h4>
                                    {currentItem.item_id && <span className="order-search-result-price fas fa-inr">&nbsp;{item.price} </span>}
                                </div>
                                {/* add to cart button */}
                                {/* {!isItemAdded(item) && currentItem.item_id != item.id && 
                                    <div className="order-search-result-a_t_c"> */}
                                {/* <span className="fas fa-add">&nbsp; Add</span> */}
                                {/* </div>} */}
                                {/* edit item in cart button */}
                                {/* {isItemAdded(item) && currentItem.item_id != item.id && <div onClick={() => { editItem(item) }} className="order-search-result-a_t_c"> */}
                                {/* <span className="fas fa-edit">&nbsp;</span> */}
                                {/* </div>} */}
                                {/* collapse quantity selector button */}
                                {currentItem.item_id && <span onClick={(e) => { collapseQuantitySelector(e) }} style={{ color: "lightgray" }} className="fas fa-angle-down"></span>}
                                {/* Quantity Selection */}
                                {currentItem.item_id && <QuantitySelector showWeightSelector={currentItem?.metadata?.cost_per_weight && currentItem?.metadata?.cost_per_weight == true} is_item_added={isItemAdded(item)} item={currentItem} cart={props.cart} itemMetrics={itemMetrics} setItemMetrics={setItemMetrics} completeAction={setCompleteAddActionComplete} remove_from_cart={removeFromCart} />}
                            </div>
                            {/* <button onClick={()=>{console.log("CartItemsL", cartController.getItems)}}>Show cart</button> */}
                        </li>
                    </ul>
                </div>

            </div>
        </>

    )

}