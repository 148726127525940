import { useEffect, useState } from "react";
import Params from "../constants";
import spinner from "../images/spinner.gif";
import { isInViewport } from "../common/utils";
import { useRef } from "react";
import OrderSearchWidget from "../order/widgets/OderSearchWidget";
import CartSkeleton from "../order/CartSkeleton";
import CartPreview from "../order/widgets/CartPreview";
import temp from "../images/temp.jpg";
import { authHeaders } from "../auth/services";
import CommonService from "../common/services";
import WaitingCard from "../order/widgets/WaitingCard";
const io = require("socket.io-client");


export default function StoreHome(props) {

    const store_url = props.store_url;
    const [ex_l, setExL] = useState(true);
    const [page, setPage] = useState(1);
    const [search_query, setSearchQuery] = useState('');
    // const fetchProductsURL = "http://shop.kiranadigital.com/api/v1/data/stores/get-results?access_token=fadsfsdfdsf&in=3ecd976c7012&q=&page=1";
    const storeId = window.location.href.split("?u=").at(-1);
    const fetchProductsURL = `${Params.CENTRAL_SERVICE_URL}/data/stores/get-results?access_token=fadsfsdfdsf&in=${storeId}&q=${search_query}&page=1`;
    const MAX_PAGES_TO_FETCH = 10;
    const PRODUCTS_PER_PAGE = 25;
    const [store_title, setStoreTitle] = useState("");
    const [store_icon, setStoreIcon] = useState("");
    const [store_banner, setStoreBanner] = useState("");
    const [current_page_count, setCurrentPageCount] = useState(25);
    const [product_count_update, setProductCountUpdated] = useState(false);
    const [selected_product, setSelectedProduct] = useState(null);
    const [show_cart, setShowCart] = useState(false);
    const [cartProcess, setCartProcess] = useState({ "processing": false, "waiting": false, "order_status": null, "metadata": {} });
    var socket = null;

    var pagesFetched = [1];
    var ref_eor = useRef(null);
    var ref_container = useRef(null);
    var break_ = true;

    // set whether to show search results or not
    const [showResults, setShowResults] = useState(false);
    const [query, setQuery] = useState("");

    const [shouldFetch, setShouldFetch] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);

    const [cart, setCart] = useState(CartSkeleton);



    // property access for all cart controls in the child components
    const cartController = {
        "getCart": cart,
        "getItems": cart.items,
        "getItemById": (id) => cart.items.filter(el => el.item_id == id).at(0),
        "getCartCount": (cart.items.length),
        "addToCart": (item) => {
            props.s_p(true);
            let updatedCart = cart;
            updatedCart.items.push(item);
            setProductCountUpdated(true);
            // console.log("update.items:", updatedCart.items);
            setCart({ ...cart, items: updatedCart.items });
            console.log("CART::", cart);
        },
        "updateItem": (item) => {
            props.s_p(true);
            let updatedCart = cart;
            // let item_to_update = updatedCart.itemsfind(it=>it.item_id == item.item_id);
            updatedCart.items = updatedCart.items.filter(el => el.item_id != item.item_id);
            updatedCart.items.push(item);

            setCart({ ...cart, items: updatedCart.items });

        },
        "removeFromCart": (item) => {
            props.s_p(true);
            let updatedCart = cart;
            setCart({ ...cart, items: updatedCart.items.filter(el => el.item_id != item.item_id) });
            console.log("CART::", cart);
        },
        "print": () => {
            cart.items.map(it => console.log(it));
        }
    }


    // check if search box is in focus
    const [searchFocus, setSearchFocus] = useState(false);

    const refreshLocalCart = () => {
        localStorage.setItem("x0-e-prev", JSON.stringify(cartController.getItems));
    }

    useEffect(() => {
        console.log("cart.items:", cart.items);
        if (product_count_update || cart.items) {
            props.s_p(false);
            let t = cart.items.reduce((i, j) => i + j.quantity, 0);
            setCart({ ...cart, total: t });
        }
    }, [product_count_update, cart.items]);

    function attachEventListener(e) {
        console.log("attaching event listener to iframe");
        setExL(false);
        window.addEventListener("message", (xe) => {
            // console.log(xe);
            if (xe.origin == Params.CENTRE_HOST) {
                if (xe.data.source == "react-devtools-content-script") return;
                console.log(xe.data);
                let event = xe.data;
                switch (event.event_name) {
                    case "__id":
                        console.log(event.data);
                        setStoreTitle(event.data['store-name']);
                        setStoreIcon(event.data['store-icon']);
                        setStoreBanner(event.data['store-banner']);
                        setCurrentPageCount(event.data['prod-count']);
                        break;
                    case "add-to-cart":
                        let p_ = event.data;
                        // setting default item metrics for a new product
                        let d = {
                            "item_id": p_['r__'],
                            "name": p_["n"],
                            "price": Number.parseFloat(p_["p"]),
                            "quantity": 1,
                            "weight": 200,
                            "unit": 1,
                            "metadata": (p_["md"] && p_["md"] != "-1") ? JSON.parse(p_["md"]) : p_["md"]
                        };

                        setSelectedProduct(d);
                        break;
                    default:
                        break;
                }
            }
        })
    }

    // Search query 

    const searchFor = (e) => {
        var val = e.target.value;
        try {
            if (val.toString().length > 2 && val.toString().match(/[a-z A-Z]/) != null) {
                setSearchQuery(val.toString());
            } else {
                setSearchQuery("");
            }
        } catch (err) {
            setSearchQuery("");
        }
    }


    // Search query ends

    useEffect(() => {
        if ((currentPage > 1) && (currentPage < MAX_PAGES_TO_FETCH) && shouldFetch) {
            console.log("Moving to next page:", currentPage);
            setPage(page => { return page + 1 });
        }
    }, [currentPage]);
    useEffect(() => {
        console.log("current page:", page);
        if ((pagesFetched.findIndex(p => p == page) == -1) && (page < MAX_PAGES_TO_FETCH) && shouldFetch) {
            var iframe = document.createElement("iframe");
            iframe.src = `${Params.CENTRAL_SERVICE_URL}/data/stores/get-results?access_token=fadsfsdfdsf&in=${window.location.href.split("?u=").at(-1)}&q=&page=${page}`;
            iframe.width = "100%";
            pagesFetched.push(page);
            //  can add event listener if required
            iframe.addEventListener("load", (e) => attachEventListener(e));
            document.querySelector("#if_cont__").insertBefore(iframe, ref_eor.current);
        }
    }, [page]);

    useEffect(() => {
        console.log("current_page_count:", current_page_count);
        if (Number(current_page_count) < PRODUCTS_PER_PAGE) {
            console.log("Setting shouldFetch to false");
            setShouldFetch(false);
        }
    }, [current_page_count]);

    useEffect(() => {
        // console.log("adding event listener e_o_r");
        // console.log("resultsCount=", resultCount);
        // console.log("ref_eor value:", ref_eor);
        if (ref_eor && ref_container) {
            console.log("event loader added");
            ref_container.addEventListener("scroll", (e) => {

                ref_eor.current.style.opacity = "0";
                if (isInViewport(ref_eor.current)) {
                    console.log("In view port");
                    // let pages = ;
                    // let balance = resultCount % DOCS_LIMIT;
                    // console.log("pages=", pages, "balance=", balance);
                    // can be changed

                    if (currentPage < MAX_PAGES_TO_FETCH) {
                        ref_eor.current.style.opacity = "1";
                        ref_eor.current.style.mixBlendMode = "multiply";
                        let new_page = currentPage + 1;
                        // console.log("newPage:", new_page);
                        if (!break_) {
                            setCurrentPage(currentPage => {
                                return currentPage + 1;
                            });
                        };
                        break_ = true;
                        // console.log("updating current page==", currentPage);
                    } else {

                        console.log("Not in view");
                        ref_eor.current.style.opacity = "0";
                    }
                } else {
                    // console.log("NOT_IN_VIEW");
                    break_ = false;
                }

            })
        }
    }, [ref_eor, ref_container]);

    useEffect(() => {
        document.querySelector(".parent").style.padding = "7px";

        var localCartItems = localStorage.getItem("x0-e-prev");
        if (localCartItems) {
            try {
                localCartItems = JSON.parse(localCartItems);
                setCart({ ...cart, items: localCartItems });
            } catch (err) {

            }
        }
        // socket = io("https://localhost:3000");
        // socket.on()
        // document.querySelector("body").style.overflow = "hidden";
    }, []);

    useEffect(() => {
        if (cart.items.length != 0) { refreshLocalCart(); }
    }, [cart.items]);

    useEffect(() => {
        console.log("cart process:", cartProcess);

        if (cartProcess.processing && !cartProcess.waiting) {
            console.log("processing  cart");
            socket = new io("https://appdata.kiranadigital.com/");
            window.activeX = socket;
            CommonService.processCart({ metadata: cartProcess.metadata }).then(data => {

                if (data.status == 200) {
                    var listenTo = data.listenTo;
                    console.log("listen to:", listenTo);

                    socket.on(listenTo, (msg) => {
                        console.log("Coming froxm server:", msg);
                        if (msg.status) {

                            switch (msg.status) {
                                case "pushed":
                                    setCartProcess({ ...cartProcess, waiting: true, order_status: "pushed" });
                                    break;
                                case "confirmed":
                                    setCartProcess({ ...cartProcess, waiting: true, order_status: "confirmed" });
                                    socket.disconnect();
                                    break;
                                case "rejected":
                                    setCartProcess({ ...cartProcess, waiting: true, order_status: "rejected" });
                                    socket.disconnect();
                                    break;
                                case "error":
                                    setCartProcess({ ...cartProcess, waiting: true, order_status: "error" });
                                    socket.disconnect();
                                    break;
                                default:
                                    break;
                            }

                        }

                    });




                }

            }).catch(err => {
                console.log("error:", err);
            })
        }
        else if (cartProcess.processing && cartProcess.waiting) {

        }
        else {
            // Cancelling
            if (window.activeX) {
                window.activeX.disconnect();
            }
            props.set_toast_message("Order not sent to store");
            document.querySelector(".parent").classList.remove("shadow");
        }

    }, [cartProcess]);

    // comment
    useEffect(() => {
        if (window.activeX) {
            window.activeX.disconnect();
        }
    }, []);

    return (
        <>
            <div ref={node => ref_container = node} className="overflow-container card-container" style={{ padding: 0, height: "100vh", "maxHeight": "100vh" }}>
                <CartPreview refreshCart={refreshLocalCart} store_id={storeId} cartProcess={cartProcess} setCartProcess={setCartProcess} cartController={cartController} set_selected_product={setSelectedProduct} show_cart={show_cart} set_show_cart={setShowCart} cart_updated={product_count_update} set_product_count_updated={setProductCountUpdated} updated_count={cart.total} cart_items={cart.items} />
                <br />

                {/* Store header */}
                <div class="flex-list-item row" style={{ "justifyContent": "center", "alignItems": "center", "backgroundImage": "url(" + temp + ")", "backgroundSize": "100% auto", "backgroundRepeat": "no-repeat", "minHeight": "200px" }}>
                    <div className="store__banner">

                        <span >
                            <img style={{ borderRadius: "100%", marginRight: "10px", overflow: "hidden" }} src={store_icon} width={40} height={40} />
                        </span>
                        <h2 style={{ textAlign: "center", color: "#FFFFFF" }}>
                            {store_title}
                        </h2>
                    </div>
                </div>
                {/* Store header ends */}
                <div style={{ background: "#ffffff", position: "sticky", "top": 0, "left": 0 }}>
                    <div style={{ padding: "5px", width: "95%", marginLeft: "2.5%", marginRight: "2.5%" }} className="card-container left-aligned margin7 rounded">
                        <div className="filter">
                            <div className="search-box card-form">
                                <div>
                                    <input onChange={(e) => searchFor(e)} style={{ width: "90%", padding: "7px" }} type="text" placeholder="search here" />
                                    <i style={{ fontSize: "1em", padding: "0px" }} className="fas fa-search"></i>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="if_cont__">
                    {/* <div className="overflow-container" style={{ maxHeight: "100vh", height: "100vh" }}> */}
                    {ex_l && <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "100px" }}><img width={"50px"} src={spinner} /></div>}
                    {/* <div style={{ position: "relative" }}> */}
                    <iframe onLoad={(e) => { attachEventListener(e) }} style={{ width: "100%" }} src={fetchProductsURL} ></iframe>
                    {/* </div> */}
                    {/* <div style={{ position: "relative" }}> */}
                    {/* <iframe style={{ width: "100%" }} src={fetchProductsURL2} ></iframe> */}
                    <div ref={ref_eor} id="e_o_r" >
                        <img src={spinner} />
                    </div>
                </div>

            </div>

            {selected_product && <OrderSearchWidget
                refreshCart={refreshLocalCart}
                show_results={showResults}
                set_show_results={setShowResults}
                set_selected_product={setSelectedProduct}
                query={""}
                set_query={setQuery}
                item={selected_product}
                set_search_focus={setSearchFocus}
                set_toast_message={props.set_toast_message}
                cart={cartController} />
            }

            {cartProcess.processing && <WaitingCard orderState={cartProcess.order_status} setCartProcess={setCartProcess} />}

        </>
    )
}

