
// class StatusCode: Returns the status info for 
// corresponding status code
// Methods: getErrorCode(), getErrorMessage()

class StatusCode {

    STATUS = {
        "500": {
            "name": "INTERNAL_SERVER_ERROR",
            "message": "There was an error in processing the request"
        },
        "404": {
            "name": "ENDPOINT_NOT_FOUND",
            "message": "Invalid end point"
        },
        "401": {
            "name": "UNAUTHORIZED",
            "message": "User not authorized"
        },
        "200": {
            "name": "SUCCESS",
            "message": "Response was success"
        },
    }
    

    status_code = -1;

    constructor(status_code) {
        this.status_code = status_code;
    }

    getErrorCode() {
        return this.STATUS[this.status_code].name;
    }

    getErrorMessage() {
        return this.STATUS[this.status_code].message;
    }
}

export { StatusCode };