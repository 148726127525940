import { useEffect, useState } from "react"
import { validateObject } from "../../common/utils";
import { ListOfItemMetrics } from "../../common/interfaces";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CommonService from "../../common/services";
import WaitingCard from "./WaitingCard";


export default function CartPreview(props) {

    const [cartProcess, setCartProcess] = useState({ "processing": false, "fetching_total": true, "show_waiting": false });
    const [cartTotals, setCartTotals] = useState(null);
    const [addRemarks, setAddRemarks] = useState(false);


    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
            if (xDiff > 0) {
                /* right swipe */
            } else {
                /* left swipe */
            }
        } else {
            if (yDiff > 0) {
                /* down swipe */
            } else {
                /* up swipe */
                collapse();
                setTimeout(() => { props.set_show_cart(false) }, 400)
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };

    useEffect(() => {
        console.log("Cart preview updated");
        var c_p_b = document.querySelector(".cart-preview-btn");
        var s_b = document.querySelector(".fas.fa-shopping-bag");

        if (c_p_b != null && s_b != null) {
            c_p_b.classList.toggle("halfBalloonAnimation");
            s_b.classList.toggle("bagAnimation");
        }

        setTimeout(() => {
            props.set_product_count_updated(false);
        }, 900);
    }, [props.cart_updated]);

    const collapse = () => {
        var s_c = document.querySelector(".shopping__list_container");
        if (s_c != null) {
            s_c.classList.add("collapse");
        }
    }

    useEffect(() => {
        if (props.show_cart) {
            var c_m = document.querySelector(".cart-minimize");
            if (c_m != null) {
                c_m.classList.toggle("bagAnimation2");
                setTimeout(() => {
                    c_m.classList.remove("shopping-bag");
                    c_m.classList.add("fa-xmark");
                }, 800);
            }
        } else {

        }
    }, [props.show_cart]);

    // const evalCartItems = (cart_items)=>{

    // }

    // useEffect(() => {
    //     if (props.cart_items.length > 0) {
    //         evalCartItems(props.cart_items);
    //     }
    // }, [props.cart_items]);
    useEffect(() => {

        /**
         * Send local cart to server
         * verify on server and return net total
         */

        if (props.show_cart) {
            props.refreshCart();
            var localCartItems = localStorage.getItem("x0-e-prev");
            if (localCartItems) {
                localCartItems = JSON.parse(localCartItems);
                CommonService.getCartTotals({ "cart_items": localCartItems, "store_id": props.store_id }).then(res => {
                    console.log("Data in response:", res);
                    if (res.status == 200) {
                        if (res.cart_total) {
                            setCartTotals(res.cart_total);
                            setCartProcess({ ...cartProcess, fetching_total: false });

                        }
                    }
                }).catch(err => {
                    console.log("Error::", err);
                })
            }
        }
        if (props.show_cart) {
            window.scrollTo(0, 0);
            if (validateObject(props.cart_items, ListOfItemMetrics)) {
                console.log("Validated cart..");
            } else {
                console.log("Cart not validated");
            }

            console.log("Cart ITems::", props.cart_items);
            console.log("Cart IIITems::", props.cartController.getCart);
            document.querySelector(".shopping__list_container").addEventListener('touchstart', handleTouchStart, false);
            document.querySelector(".shopping__list_container").addEventListener('touchmove', handleTouchMove, false);
        }

    }, [props.show_cart])

    useEffect(() => {

        // setCartProcess({...cartProcess, fetching_total:true});

    }, []);

    const proceedToCheckout = () => {

        setCartProcess({ ...cartProcess, processing: true, show_waiting: true });
        collapse();
        setTimeout(() => { props.set_show_cart(false) }, 400)
        props.setCartProcess({ "processing": true, "waiting": false, "metadata": { "remarks": (addRemarks ? document.querySelector("#remarks").value : "") } });

    }

    return (<>

        {!props.show_cart && <div className="cart-preview-btn" onClick={(e) => props.set_show_cart(true)}>
            <span className="cart-count">{props.updated_count}</span>
            <span className="fas fa-shopping-bag" style={{ padding: "2px" }}></span>
        </div>}
        {props.show_cart && <div className="cart-preview-btn" onClick={(e) => { collapse(); setTimeout(() => { props.set_show_cart(false) }, 400) }} style={{ justifyContent: "center" }}>
            <span className="fas fa-shopping-bag cart-minimize" style={{ padding: "2px" }}></span>
        </div>}
        {props.cart_items.length == 0 && props.show_cart && <div className="shopping__list_container card-container">
            <h3 style={{ textAlign: "center" }}>Shopping List</h3>
            <h4>No Items here</h4>
        </div>}
        {(props.cart_items.length > 0) && props.show_cart && <div className="shopping__list_container card-container overflow-container" style={{ maxHeight: "100vh" }}>
            <h3 style={{ textAlign: "center" }}>Shopping List</h3>
            <ul className="shopping__list">
                {props.cart_items.map(item => {
                    return <li>
                        <div>
                            <span style={{ fontFamily: "monospace" }}>
                                {item.quantity}
                            </span>
                            <span className="fas fa-times"></span>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontWeight: "bold", fontSize: "0.9rem", fontFamily: "monospace" }}>
                                    {item.name}
                                </span>
                                <hr />
                            </div>
                            {/* <div style={{display:"block", "height":"1px", "background":"black"}}></div> */}
                            <span style={{ fontFamily: "monospace" }}>
                                &nbsp;&nbsp;<span>&#8377;</span>&nbsp;{(item.quantity * item.price).toFixed(2)}
                            </span>
                            <span></span>
                            {/* <span></span> */}
                            <span onClick={() => { collapse(); setTimeout(() => { props.set_show_cart(false) }, 400); props.set_selected_product(item) }} style={{ fontSize: "0.8em" }} className="fas fa-pen"></span>
                        </div>
                    </li>


                })}
            </ul>
            <hr />
            {cartProcess.fetching_total && < SkeletonTheme width={"100%"} height={"100px"}>
                <div style={{ width: "40%", margin: "10px", "margin-left": "55%", "marginRight": "5%" }}>

                    <Skeleton count={3} height={15} style={{ marginBottom: "5px" }} />
                </div>

            </SkeletonTheme>}
            {!cartProcess.fetching_total && <div className="cart__totals">
                <strong>Net Total:</strong><span style={{ fontFamily: "monospace" }}> <span>&#8377;</span>&nbsp;{Number.parseFloat(cartTotals.price.toString()).toFixed(2)} </span>

            </div>}
            {/* Order Toal Amount including Delivery fee and all */}
            <br /><br />
            <div>
                <p onClick={() => { setAddRemarks(!addRemarks) }} className="action-link clickable">Add Remarks</p>
                {addRemarks && <div className="field card-form">
                    <label>Remarks</label>
                    <textarea id="remarks" placeholder="Write your remarks here"></textarea>
                </div>}
            </div><br /><br />
            {!props.cartProcess.processing && <button onClick={() => proceedToCheckout()} className="btn btn-dark">Continue</button>}

        </div >
        }

    </>)
}