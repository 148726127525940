
import { useState } from "react";
import logo_path from "./images/logo.png";
import spinner from "./images/spinner.gif";

function Header(props) {

    const processing_state = props.processing_state;



    return (<>
        <div className="notification-bar">
            <p>Your order is on the way! </p>
        </div>
        <div className="header">
            <div className="branding">
                <img src={logo_path} alt="" />
                <h4>{props.header_title}</h4>
            </div>

            <div className="menu-bar">

            </div>
            {processing_state && <div className="loader-container"><div className="loader"></div></div>}

        </div>

    </>
    );
}

export default Header;