import { BrowserRouter, HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './Header';
import SplashScreen from './SplashScreen';
import StoreSearchScreenSkeleton from './store-search-skeleton-card';
import { useEffect, useState } from 'react';

import IntroScreen from './IntroScreen';
import AuthorizationRequired from './AuthorizationRequired';

// Firebase config
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import LoginScreen from './auth/auth-user-login';
import Toast from './Toast';
import { getAuth } from 'firebase/auth';
import AuthGuard from './auth/guards/AuthGuard';
import UnAuthGuard from './auth/guards/UnAuthGuard';
import OrderShoppingListScreen from './order/OrderShoppingListScreen';
import UserSignUp from './auth/auth-user-signup';
import StoreHome from './store';
import Checkout from './payment/Checkout';



function App() {

  // Page loading state
  const [isLoading, setLoading] = useState(true);

  // Set Header title
  const [headerTitle, setHeaderTitle] = useState("Shopping List");

  // Background process state
  const [isProcessing, setIsProcssing] = useState(false);

  // Splash screen state
  const [showSplash, setShowSplash] = useState(false);

  // Check Login state
  const [isLoginScreen, setIsLoginScreen] = useState(false);

  // Toast state
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  // global auth state
  const [i_l, setIsLoggedIn] = useState(false);

  // initialize firebase
  const firebaseConfig = {
    apiKey: "AIzaSyAxUesVd_kBYHQk2jFUdoOrl197ppeueU8",
    authDomain: "kiranadigital-a6543.firebaseapp.com",
    projectId: "kiranadigital-a6543",
    storageBucket: "kiranadigital-a6543.appspot.com",
    messagingSenderId: "1054678396620",
    appId: "1:1054678396620:web:13010cae27588364b85c69",
    measurementId: "G-X8M5450YRR"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);




  useEffect(() => {
    // Splash screen timeout

    // Check if login screen or not
    if (window.location.pathname == "/login") {
      setIsLoginScreen(true);
    }


    setTimeout(() => {
      setShowSplash(false);
    }, 2000);
  }, []);


  useEffect(() => {
    if (toastMessage.length > 0) {
      setShowToast(true);
      setTimeout(() => { setShowToast(false); setToastMessage("") }, 1000);
    }
  }, [toastMessage]);

  useEffect(() => {
    console.log("is this login:", isLoginScreen);
  }, [isLoginScreen]);


  return (
    <>
      {!showSplash && !isLoginScreen && <>
        <Header header_title={headerTitle} processing_state={isProcessing} />
      </>}
      <div className='parent'>
        {/* <Toast message="Test message" /> */}
        {showToast && <Toast message={toastMessage} />}
        <HashRouter>
          <Routes>
            <Route key="home" path='/' index element={<IntroScreen s_p={setIsProcssing} set_header_title={setHeaderTitle} set_show_splash={setShowSplash} set_login_screen={setIsLoginScreen} p_s={isProcessing} />} />
            <Route key="store" path='/store/results' element={<StoreHome s_p={setIsProcssing} p_s={isProcessing} set_toast_message={setToastMessage} />} />
            <Route key="order-shopping-list-screen" path="/shop" element={<UnAuthGuard component={<OrderShoppingListScreen set_toast_message={setToastMessage} set_header_title={setHeaderTitle} s_p={setIsProcssing} />} />} />
            <Route key="login" path="/login" element={<UnAuthGuard component={<LoginScreen set_login_screen={setIsLoginScreen} set_show_splash={setShowSplash} set_toast_message={setToastMessage} is_splash_in_view={showSplash} />} />} />
            <Route key="register" path="/register" element={<UserSignUp set_login_screen={setIsLoginScreen} set_show_splash={setShowSplash} set_toast_message={setToastMessage} is_splash_in_view={showSplash} />} />
            <Route key="create" path='/create' element={<Checkout />} />
          </Routes>
        </HashRouter>
    
      </div>
      {showSplash && <SplashScreen />}


    </>
  );
}

export default App;
