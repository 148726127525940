

const SCALE_FACTOR_POINT_5 = 1.5;
const SCALE_FACTOR_POINT_1 = 150;

export function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var adjustment = -10; // meters
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    return Number((d * 1000).toFixed(0)) + adjustment;
}

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}


export function measure(lat1, lon1, lat2, lon2) {  // generally used geo measurement function
    var R = 6378.137; // Radius of earth in KM
    var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d * 1000).toFixed(0); // meters
}


export function shiftDecimal(n) {

    if (n > 0.001 || n == 0) return n;
    n = n * 10;
    return shiftDecimal(n);

}


export function adjustScale(val) {
    return (val > 0.2) ? (val *= SCALE_FACTOR_POINT_5) : (val *= SCALE_FACTOR_POINT_1);
}


export function isInViewport(element, offset = 0) {

    const top = element.getBoundingClientRect().top;

    return (top + offset) >= 0 && (top - offset) <= (window.innerHeight + 90);
}



const exists = (k, o) => o[k] !== undefined || o[k] === null;

export class TypedArray {


    constructor(t) {
        this.array_type = t;
    }

    getType() {
        return this.array_type;
    }

}

export class CustomType {
    constructor(t) {
        this.custom_type = t;
    }

    getType() {
        return this.custom_type;
    }
}

export class OptionType {
    constructor(...t) {
        this.options = t;
    }

    getOptions() {
        return this.options;
    }
}

export const validateObject = (o, types) => {

    if(types instanceof TypedArray){
        var subtype = types.getType();
        if (subtype) {
            for (var i in o) {
                if (!validateObject(o[i], subtype)) return false;
            }
        }

        return true;
    }
    var keys = Object.keys(types);

    console.log(o,keys);
    try {
        for (var k in keys) {

            if (!exists(keys[k], o)) return false;

            if (o[keys[k]] == null) continue;

            if (types[keys[k]] instanceof OptionType) {
                var options = types[keys[k]].getOptions();
                if (options.findIndex(op => op === o[keys[k]]) != -1) continue;
            }

            if (types[keys[k]] instanceof CustomType) {
                var subtype = types[keys[k]].getType();
                if (subtype) {
                    if (validateObject(o[keys[k]], subtype)) return true;
                }

                return false;
            }

            if (types[keys[k]] instanceof TypedArray) {
                var subtype = types[keys[k]].getType();
                if (subtype) {

                    for (var i in o[keys[k]]) {
                        if (!validateObject(o[keys[k]][i], subtype)) return false;
                    }
                    continue;
                }

                return false;
            }

            if (typeof o[keys[k]] != types[keys[k]] && o[keys[k]] != null) return false;
        }
    } catch (err) {
        console.log("Error:", err);
        return false;
    }

    return true;
};



