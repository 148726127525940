
// ORDER/SHOPPING LIST
// This component handles the initial phase of 
// placing an order i.e. creating a shopping list

import { Link } from "react-router-dom";
import OderSearchWidget from "./widgets/OderSearchWidget";
import { useEffect, useState } from "react";
import CartSkeleton from "./CartSkeleton";
import search_heading_card_path from "../images/search-heading-card.png";
import bottom_graphic from "../images/bottom-graphic.gif";
// import "firebase/auth";
import { getAuth } from "firebase/auth";
import AuthService, { authHeaders } from "../auth/services";


export default function OrderShoppingListScreen(props) {

    // PROPS: set_header_title [change nav title]
    // PROPS: s_p [change processing state]


    props.set_header_title("Shopping");
    const [cart, setCart] = useState(CartSkeleton);

    // search heading card
    const [showCard, setShowCard] = useState(true);

    // show bottom graphic
    const [showGraphic, setShowGraphic] = useState(true);

    // check if search box is in focus
    const [searchFocus, setSearchFocus] = useState(false);

    // search query state
    const [query, setQuery] = useState("");

    // set whether to show search results or not
    const [showResults, setShowResults] = useState(false);



    // property access for all cart controls in the child components
    const cartController = {
        "getCart": cart,
        "getItems": cart.items,
        "getItemById": (id) => cart.items.filter(el => el.item_id == id).at(0),
        "getCartCount": (cart.items.length),
        "addToCart": (item) => {
            props.s_p(true);
            let updatedCart = cart;
            updatedCart.items.push(item);
            setCart({ ...cart, items: updatedCart.items });
            console.log("CART::", cart);
        },
        "removeFromCart": (item) => {
            props.s_p(true);
            let updatedCart = cart;
            setCart({ ...cart, items: updatedCart.items.filter(el => el.item_id != item.item_id) });
            console.log("CART::", cart);
        },
        "print": () => {
            cart.items.map(it => console.log(it));
        }
    }


    useEffect(() => {
        console.log(cartController.getItems());
        props.s_p(false);
    }, [cart]);



    return (
        <>{!props.is_splash_in_view &&
            <>
                {!searchFocus && !showResults && <div className="search-heading-card">
                    {/* Shop from your local stores... */}
                    {showCard && <img src={search_heading_card_path} onError={() => { setShowCard(false); }} />}
                </div>}
                <OderSearchWidget
                    show_results={showResults}
                    set_show_results={setShowResults}
                    query={query}
                    set_query={setQuery}
                    set_search_focus={setSearchFocus}
                    set_toast_message={props.set_toast_message}
                    cart={cartController} />
                {/* <Link to={"/"}>Go back</Link> */}
                {showGraphic && !showResults && <div className="search-bottom-graphic">
                    <img src={bottom_graphic} />
                </div>}
            </>
        }
            <style>

            </style>
        </>
    )

}

