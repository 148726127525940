import { CustomType, OptionType, TypedArray } from "./utils"

export const weight = {
    unit: new OptionType(1, 2, 3),
    value: 'number'
}

export const OrderItem = {
    item_id: 'string',
    name: 'string',
    unit_price: 'number',
    total: 'number',
    weight: new CustomType(weight)

}


// var item_metrics = {
//     "item_id": props.item.item_id,
//     "quantity": 1,
//     "weight": 200,
//     "unit": 1,
//     "name": "props.item.name",
//     "price": "props.item.price"
// };

export const ItemMetrics = {
    item_id: 'string',
    name: 'string',
    quantity: 'number',
    weight: 'number',
    unit: 'number',
    price: 'number'
}

export const ListOfItemMetrics = new TypedArray(ItemMetrics);