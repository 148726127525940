import { StatusCode } from "./Status";

const KDResponse = (body, status) => {

    // default response object
    var responseObject = { "response": {}, "status": status, "message": "No body in response" };

 
    return new Promise((resolve, reject) => {
        if (isNaN(body) || body.__proto__.constructor.name != 'Object' || Object.keys(body).length == 0 || !(status instanceof StatusCode)) {
            reject(responseObject);
        }

        
        resolve(responseObject);

    });

}




export default KDResponse;