import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CommonService from "./common/services";
import { adjustScale, calcCrow, measure, shiftDecimal } from "./common/utils";
import store_default_icon from "./images/store-default-icon.png";
import Params from "./constants";
import search_grid from "./images/search-grid.png";
import home_graphic from "./images/home-graphic.gif";
import spinner from "./images/spinner.gif";

export default function IntroScreen(props) {


    props.set_login_screen(false);
    props.set_show_splash(false);

    props.set_header_title("Search Stores");
    const LONGITUDE_UNIT_DEGREE_KM = 0.00902;
    const LATITUDE_UNIT_DEGREE_KM = 0.00898;
    const MAX_QUAD_WIDTH = 110;
    const MAX_QUAD_HEIGHT = 110;
    const MAX_SEARCH_RADIUS = 400; // meters
    const ADJUSTMENT_FACTOR = 20;
    const METER_PER_PX = (MAX_SEARCH_RADIUS / MAX_QUAD_HEIGHT) * ADJUSTMENT_FACTOR;
    const CLUSTER_OFFSET = 120;
    const [coords, setCoords] = useState(null);
    const [scatterStores, setScatterStores] = useState([]);
    const [closeByStores, setCloseByStores] = useState([]);
    const [stores_found, setStoresFound] = useState([]);
    const [status, setStatus] = useState("");
    const [storesList, setStoresList] = useState([]);

    const [markers, setMarkers] = useState({
        "nw": [],
        "ne": [],
        "sw": [],
        "se": [],
        "total": 0
    });

    const searchStores = () => {
        // navigator.geolocation.getCurrentPosition(data => {
        //     console.log("locaiton::", data);
        //     setCoords({ "coordinates": { "latitude": data.coords.latitude, "longitude": data.coords.longitude } });
        // })

        setCoords({ "coordinates": { latitude: 28.557714729533682, longitude: 77.28797703330841 } });
    }

    const adjustMarkers = () => {

        var markers = document.querySelectorAll(".sm_st_container");
        markers = Array.prototype.slice.call(markers);
        if (closeByStores.length > 0) {
            console.log("adjusting close by stores");
            closeByStores.map(c_b_s => {
                var stores = c_b_s;
                console.log("c_b_s:", (c_b_s.at(1) - c_b_s.at(0)));
                // start traversal from the second store onwards
                for (var i = 1; i < stores.length; i++) {
                    let marker = markers.at(stores.at(i));
                    marker.style.transform = `translate(-${CLUSTER_OFFSET * (c_b_s.at(1) - c_b_s.at(0))}%,-${CLUSTER_OFFSET * (c_b_s.at(1) - c_b_s.at(0))}%)`;
                }
            })


            setCloseByStores([]);
            return;
        }
        var c = 0;
        var shiftPixels = [];
        markers.forEach(marker => {
            c += 1;
            let quad = Number(marker.getAttribute("data-qu"));
            let t_x = Math.abs(Number(marker.getAttribute("data-cx-sd")).toFixed(6));
            let t_y = Math.abs(Number(marker.getAttribute("data-cy-sd")).toFixed(6));
            console.log("t_XX:", Math.abs(t_x));
            console.log("t_YY:", Math.abs(t_y));
            t_x = (t_x != 0) ? (1 % t_x) : 0;
            t_y = (t_y != 0) ? (1 % t_y) : 0;
            console.log("t_x:", Math.abs(t_x));
            console.log("t_y:", Math.abs(t_y));

            // if (t_x < 0.5) t_x *= 10000;
            // if (t_y < 0.5) t_y *= 10000;
            t_x = adjustScale(shiftDecimal(Math.abs(t_x)));
            t_y = adjustScale(shiftDecimal(Math.abs(t_y)));

            console.log("adjusted t_x:", t_x, "adjusted t_y:", t_y);

            var shift_px_x = (t_x * METER_PER_PX);
            var shift_px_y = (t_y * METER_PER_PX);

            if (shift_px_x > MAX_QUAD_WIDTH) shift_px_x = MAX_QUAD_WIDTH;
            if (shift_px_y > MAX_QUAD_HEIGHT) shift_px_y = MAX_QUAD_HEIGHT;

            console.log("shift_X:", shift_px_x, "shift_Y:", shift_px_y);
            shiftPixels.push({ x: shift_px_x, y: shift_px_y, n: marker.name });
            // marker.style.animationPlayState = "running";
            if (t_x < MAX_QUAD_WIDTH && t_y < MAX_QUAD_HEIGHT) {
                switch (quad) {
                    case 1:
                        marker.style.bottom = `${shift_px_y}px`;
                        marker.style.left = `${shift_px_x}px`;
                        console.log("Setting for 1");
                        break;
                    case 2:
                        marker.style.bottom = `${shift_px_y}px`;
                        marker.style.right = `${shift_px_x}px`;
                        console.log("Setting for 2");
                        break;
                    case 3:
                        marker.style.top = `${shift_px_y}px`;
                        marker.style.right = `${shift_px_x}px`;
                        break;
                    case 4:
                        marker.style.top = `${shift_px_y}px`;
                        marker.style.left = `${shift_px_x}px`;
                        break;
                    default:

                }
                // marker.style.transform = `translate(${(t_x / Math.abs(t_x)) * 45}%, ${(t_y / Math.abs(t_y) * 45)}%)`
                // marker.style.transform = `translate(-${shift_px_x}%, ${shift_px_y}%)`;
            } else {

            }
        })
        console.log("markers:::=", markers);
        setScatterStores(shiftPixels);

    }

    const adjustMarkersForCloseByStores = () => {

        var markers = document.querySelectorAll(".sm_st_container");
        markers = Array.prototype.slice.call(markers);
        if (closeByStores.length > 0) {
            console.log("adjusting close by stores");
            closeByStores.map(c_b_s => {
                var stores = c_b_s;
                console.log("c_b_s:", (c_b_s.at(1) - c_b_s.at(0)));
                // start traversal from the second store onwards
                for (var i = 1; i < stores.length; i++) {
                    let marker = markers.at(stores.at(i));
                    if ((c_b_s.at(1) - c_b_s.at(0)) == 2) {
                        marker.style.transform = `translate(${CLUSTER_OFFSET * (c_b_s.at(1) - c_b_s.at(0))}%,${CLUSTER_OFFSET * (c_b_s.at(1) - c_b_s.at(0))}%)`;
                    } else {

                        marker.classList.add("cl__");
                        marker.style.transform = `translate(-${CLUSTER_OFFSET * (c_b_s.at(1) - c_b_s.at(0))}%,-${CLUSTER_OFFSET * (c_b_s.at(1) - c_b_s.at(0))}%)`;
                    }

                }
            })
            setCloseByStores([]);
            return;
        }
    }

    const goToStore = (_id) => {

        // redirect to store
        window.location.href = `/#/store/results?u=${_id}`;
    }

    useEffect(() => {
        let call_search_api = async () => {
            props.s_p(true);
            setStatus("Searching stores near you...");
            await CommonService.getStoresForCurrentLocation(coords).then(
                (data) => {
                    // setDump("Got response");
                    if (data) props.s_p(false);
                    console.log("Stores Data::", data);
                    setStoresFound(data);
                    setStoresList(data);
                    if (data.length == 0) { setStatus("No stores found") } else { setStatus(`Woo Hoo! ${data.length} stores found near you`) }
                }
            ).catch(err => {
                setStatus("A problem occurred :(")
                props.s_p(false);
                // setDump(err.toString());
                console.log("Stores Data err::", err);
            })
        }
        if (coords != null && coords.coordinates.latitude != undefined) {
            // setDump(coords.coordinates.latitude);
            // setDump("Calling api");
            call_search_api();
        }
    }, [coords]);

    useEffect(() => {
        if (stores_found && stores_found.length > markers.total) {

            const centre_x = coords.coordinates.latitude;
            const centre_y = coords.coordinates.longitude;

            stores_found.map(store => {

                let x = store.distance.c_x;
                let y = store.distance.c_y;
                console.log("x=", x, "y=", y);
                let marker = {
                    'icon': store.metadata ? store.metadata.ic_url : "",
                    'f_x': store.distance.c_x,
                    'f_y': store.distance.c_y,
                    'name': store.store_name,
                    'dist': measure(centre_x, centre_y, store.coordinates.latitude, store.coordinates.longitude)
                };
                console.log("distance:", centre_x, centre_y, store.coordinates.latitude, store.coordinates.longitude);
                console.log("calCrow:", measure(centre_x, centre_y, store.coordinates.latitude, store.coordinates.longitude));
                if (x >= 0 && y >= 0) {
                    let temp = markers.ne;
                    temp.push(marker);
                    setMarkers(prev => {
                        return { ...markers, ne: temp, total: prev.total + 1 }
                    })
                }
                else if (x <= 0 && y >= 0) {
                    let temp = markers.nw;
                    temp.push(marker);
                    setMarkers(prev => {
                        return { ...markers, nw: temp, total: prev.total + 1 }
                    })
                }
                else if (x <= 0 && y <= 0) {
                    let temp = markers.sw;
                    temp.push(marker);
                    setMarkers(prev => {
                        return { ...markers, sw: temp, total: prev.total + 1 }
                    })
                }
                else if (x >= 0 && y <= 0) {
                    let temp = markers.se;
                    temp.push(marker);
                    setMarkers(prev => {
                        return { ...markers, se: temp, total: prev.total + 1 }
                    })
                }
                else { }


            })

            setStoresFound([]);

        }
    }, [stores_found]);

    useEffect(() => {
        console.log("Markers:", markers);
        adjustMarkers();
    }, [markers]);

    useEffect(() => {
        adjustMarkersForCloseByStores();
    }, [closeByStores])

    useEffect(() => {
        if (scatterStores) {
            console.log(scatterStores);
            var closeByStores = [];
            for (var i = 0; i < scatterStores.length; i++) {
                // var j = i+1;
                for (var k = i + 1; k < scatterStores.length; k++) {
                    if (Math.abs(scatterStores.at(i).x - scatterStores.at(k).x) < 0.001
                        && Math.abs(scatterStores.at(i).y - scatterStores.at(k).y) < 0.001) {
                        closeByStores.push([i, k]);
                    }
                }
            }
            console.log("closeByStores:", closeByStores);
            setCloseByStores(closeByStores);

        }

    }, [scatterStores]);



    return (<>
        <div className="card-container floating-container container">
            <div className="wrapper">
                <img src={home_graphic} />
                <br /><br />
                <div style={{ backgroundImage: `url(${search_grid})`, backgroundPosition: "center center", backgroundSize: "contain" }} className="store_monitor">
                    <div className="center"></div>
                    <div className="radial">
                    </div>
                    <div className="sm_quad store_monitor_nw">
                        {markers.nw && markers.nw.map(m => {
                            return <div data-qu="2" data-cx-sd={m.f_x} data-cy-sd={m.f_y} className="sm_st_container">
                                <div className="img-container">
                                    <img src={m.icon ? m.icon : store_default_icon} />
                                </div>
                                <span className="name">{m.name}</span>
                                <span className="distance">{m.dist}m</span>
                                <span className="pin"></span>

                            </div>

                        })}

                        {/* <div data-cx-sd="0.00008005275602978145" data-cy-sd="0.00005871802558170657" class="sm_st_container" ><div class="img-container"><img src="/static/media/store-default-icon.8d43b8bcbeb9f0a9080c.png" /></div><span class="name">Hhsjsis snsj</span><span class="distance">9m</span></div>
                    <div data-cx-sd="0.00008005275602978145" data-cy-sd="0.00005871802558170657" class="sm_st_container" ><div class="img-container"><img src="/static/media/store-default-icon.8d43b8bcbeb9f0a9080c.png" /></div><span class="name">Hhsjsis snsj</span><span class="distance">9m</span></div>
                    <div data-cx-sd="0.00008005275602978145" data-cy-sd="0.00005871802558170657" class="sm_st_container" ><div class="img-container"><img src="/static/media/store-default-icon.8d43b8bcbeb9f0a9080c.png" /></div><span class="name">Hhsjsis snsj</span><span class="distance">9m</span></div> */}
                    </div>
                    <div className="sm_quad store_monitor_ne">
                        {markers.ne && markers.ne.map(m => {
                            return <div data-qu="1" data-cx-sd={m.f_x} data-cy-sd={m.f_y} className="sm_st_container">
                                <div className="img-container">
                                    <img src={m.icon ? m.icon : store_default_icon} />
                                </div>
                                <span className="name">{m.name}</span>
                                <span className="distance">{m.dist}m</span>
                                <span className="pin"></span>
                            </div>
                        })}

                        {/* <div data-qu="1" data-cx-sd="0" data-cy-sd="0" className="sm_st_container" style={{ "bottom": " 0px", "left": "0px" }}><div class="img-container"><img src={store_default_icon} /></div><span className="name">Store 7</span><span className="distance">0m</span><span className="pin"></span> </div> */}
                    </div>
                    <div className="sm_quad store_monitor_sw">
                        {markers.sw && markers.sw.map(m => {
                            return <div data-qu="3" data-cx-sd={m.f_x} data-cy-sd={m.f_y} className="sm_st_container">
                                <div className="img-container">
                                    <img src={m.icon ? m.icon : store_default_icon} />
                                </div>
                                <span className="name">{m.name}</span>
                                <span className="distance">{m.dist}m</span>
                                <span className="pin"></span>

                            </div>
                        })}

                    </div>
                    <div className="sm_quad store_monitor_se">
                        {markers.se && markers.se.map(m => {
                            return <div data-qu="4" data-cx-sd={m.f_x} data-cy-sd={m.f_y} className="sm_st_container">
                                <div className="img-container">
                                    <img src={m.icon ? m.icon : store_default_icon} />
                                </div>
                                <span className="name">{m.name}</span>
                                <span className="distance">{m.dist}m</span>
                                <span className="pin"></span>

                            </div>
                        })}
                        {/* <div data-qu="4" data-cx-sd="0.00001027046631918438" data-cy-sd="-0.0006010333084134345" className="sm_st_container" style={{ "top": " 58.5818px", "left": "109.091px" }}><div class="img-container"><img src="/static/media/store-default-icon.8d43b8bcbeb9f0a9080c.png" /></div><span className="name">Store 6</span><span className="distance">59m</span></div> */}
                        {/* <div data-cx-sd="-0.00008005275602978145" data-cy-sd="-0.00005871802558170657" class="sm_st_container" ><div class="img-container"><img src="/static/media/store-default-icon.8d43b8bcbeb9f0a9080c.png" /></div><span class="name">Hhsjsis snsj</span><span class="distance">9m</span></div> */}
                    </div>
                </div>
                <br /><br />
                {status && <h4 style={{ textAlign: "center", fontFamily: "Poppins, sans-serif", color: "gray", fontWeight: "lighter" }}> {status}  </h4>}<br />
                <ul className="">
                    {storesList && storesList.map((store, ind) => {
                        return <li onClick={(e) => { goToStore(store.store_mask_id); }} style={{ paddingBottom: "20px", paddingTop: "20px", marginTop: "5px" }} className="flex-list-item card-container row">
                            <img style={{ width: "60px" }} src={store.thumbnail_url ? store.thumbnail_url : store_default_icon} />
                            <div className="flex-item">
                                <h4>{store.store_name}</h4>
                                <span style={{ color: "darkgray", fontSize: "0.9em" }}>{store.store_address}</span>
                            </div>
                        </li>

                    })}
                </ul><br />
                <div className="float-center">

                    {!props.p_s && <button style={{ width: "100%" }} onClick={() => searchStores()} className="btn-dark">Search again</button>}
                    {props.p_s && <button disabled style={{ width: "270px" }} onClick={() => searchStores()} className="btn-dark">Looking for stores</button>}
                    <br /><br />
                    <h3 className="md-font" style={{ fontSize: "0.85rem", color: "gray", opacity: "0.35", letterSpacing: "0.85cap" }}>KIRANA DIGITAL</h3>
                    <br />
                </div><br />
            </div>
            {/* <div className="spacer"></div> */}
        </div>
    </>)
}