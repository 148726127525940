// Firebase config
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import Params from '../constants';
import { getApp } from 'firebase/app';


var auth_headers = Params.AXIOS_AUTH_HEADERS(localStorage.getItem("x-t"));



const AuthService = {};

export const authHeaders = async () => {
// Temporary

    return AuthService.getUser().then(user => {
        return user.getIdToken().then(data => {
            auth_headers = Params.AXIOS_AUTH_HEADERS(data);
            console.log("auth_headers:", auth_headers);
            return auth_headers;
        })
    })


}


AuthService.registerUser = async (body) => {

    return AuthService.getUser().then(user => {
        body.phone_number = user.phoneNumber;
        body.uid = user.uid;
        console.log(body);
        return axios.post(Params.SERVICE_BASE_URL + Params.AUTH_REGISTER, body).then(res => res.data).catch(err => err.response);
    }).catch(err => console.log(err));

    // send user to the backend and create a record
}

AuthService.checkUserExists = async (body) => {
    return axios.post(Params.SERVICE_BASE_URL + Params.AUTH_CHECK_USER, body).then(res => res.data).catch(err => err.response);
}

AuthService.getUser = () => {

    return new Promise((res, rej) => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {

            if (user) {

                res(user);
            } else {
                console.log("User not found here");
                rej(false);
            }
        })

    })

}


AuthService.logOut = () => {
    return new Promise((res, rej) => {
        const auth = getAuth();
        auth.signOut().then(() => {
            res({ "message": "Logged out successfully", "status": true });
        }).catch(() => {
            rej({ "message": "An error occurred", "status": false });
        })

    })
}

export default AuthService;