import { useEffect, useState } from "react";

export default function QuantitySelector(props) {


    const STEP_WEIGHT_G = 50;
    const STEP_WEIGHT_KG = 0.5;

    const complete_add_to_cart = () => {
        props.completeAction(true);
    };

    const remove_from_cart = () => {
        props.remove_from_cart();
    }

    // console.log("produict::", props.item, props.itemMetrics);
    var item_metrics = (props.item.id == props.itemMetrics.item_id) ? props.itemMetrics : {
        "item_id": props.item.item_id,
        "name": props.item.name,
        "price": props.item.price,
        "quantity": props.item.quantity,
        "weight": props.item.weight,
        "unit": props.item.unit
    };

    // Units: {"g"->1 , "kg"->2}
    const units = {
        "1": "g",
        "2": "kg"
    }

    // Convert grams to kilograms
    let gToKg = (val) => {
        return parseFloat(val / 1000).toFixed(1);
    }

    var increment = (METRIC) => {
        switch (METRIC) {
            case "quantity":
                let qty = props.itemMetrics.quantity + 1;
                if (qty > 100) {
                    qty = 100;
                }
                props.setItemMetrics({
                    "item_id": props.item.item_id,
                    "quantity": qty,
                    "weight": props.itemMetrics.weight,
                    "unit": props.itemMetrics.unit,
                    "name": props.item.name,
                    "price": props.item.price
                })

                break;
            case "weight":
                let step_weight = (props.itemMetrics.unit == 1) ? STEP_WEIGHT_G : STEP_WEIGHT_KG;
                let weight = props.itemMetrics.weight;
                if (step_weight == STEP_WEIGHT_KG) {
                    // kilograms
                    weight = parseFloat(weight);
                }

                weight += step_weight;

                // Check if weight exceeds 1000 grams
                // Convert unit to Kg
                if (weight > 999) {
                    props.setItemMetrics({
                        "item_id": props.item.item_id,
                        "quantity": props.itemMetrics.quantity,
                        "weight": gToKg(weight),
                        // set unit to 2(KG)
                        "unit": 2,
                        "name": props.item.name,
                        "price": props.item.price
                    })
                    break;
                }

                props.setItemMetrics({
                    "item_id": props.item.item_id,
                    "quantity": props.itemMetrics.quantity,
                    "weight": weight,
                    "unit": props.itemMetrics.unit,
                    "name": props.item.name,
                    "price": props.item.price
                })
                break;
            default:
                break;
        }
    }

    var decrement = (METRIC) => {

        switch (METRIC) {
            case "quantity":
                if (props.itemMetrics.quantity == 1) {
                    break;
                }

                let qty = props.itemMetrics.quantity - 1;
                if (qty > 100) {
                    qty = 100;
                }
                props.setItemMetrics({
                    "item_id": props.item.item_id,
                    "quantity": qty,
                    "weight": props.itemMetrics.weight,
                    "unit": props.itemMetrics.unit,
                    "name": props.item.name,
                    "price": props.item.price
                })
                break;
            case "weight":
                let step_weight = (props.itemMetrics.unit == 1) ? STEP_WEIGHT_G : STEP_WEIGHT_KG;
                let weight = props.itemMetrics.weight;
                if (weight < step_weight) {
                    break;
                }
                if (step_weight == STEP_WEIGHT_KG) {
                    // kilograms
                    weight = parseFloat(weight);
                }

                weight -= step_weight;

                // Check if weight exceeds 1000 grams
                // Convert unit to Kg
                if (weight > 1000) {
                    props.setItemMetrics({
                        "item_id": props.item.item_id,
                        "quantity": props.itemMetrics.quantity,
                        "weight": gToKg(weight),
                        // set unit to 2(KG)
                        "unit": 2,
                        "name": props.item.name,
                        "price": props.item.price
                    })
                    break;
                }

                props.setItemMetrics({
                    "item_id": props.item.item_id,
                    "quantity": props.itemMetrics.quantity,
                    "weight": (props.itemMetrics.unit == 1) ? weight : weight.toFixed(1),
                    "unit": props.itemMetrics.unit,
                    "name": props.item.name,
                    "price": props.item.price
                })
                break;
            default:
                break;
        }
    }

    var handleCustomMetrics = (field, METRIC) => {


        if (!(typeof (parseInt(field.value)) != NaN && typeof (parseFloat(field.value)) != NaN)) {
            return;
        }
        switch (METRIC) {
            case "quantity":
                let input_qty = parseInt(field.value);
                console.log("parsed::", input_qty);
                if (isNaN(input_qty)) {
                    input_qty = 1;
                }
                if (input_qty < 1) {
                    break;
                }
                let qty = input_qty;

                if (input_qty > 100) {
                    qty = 100;
                }
                props.setItemMetrics({
                    "item_id": props.item.item_id,
                    "quantity": qty,
                    "weight": props.itemMetrics.weight,
                    "unit": props.itemMetrics.unit,
                    "name": props.item.name,
                    "price": props.item.price
                })

                break;
            case "weight":
                let input_weight = parseFloat(field.value);
                if (isNaN(input_weight)) {
                    input_weight = 0;
                }
                let step_weight = (props.itemMetrics.unit == 1) ? STEP_WEIGHT_G : STEP_WEIGHT_KG;
                let weight = input_weight;
                if (step_weight == STEP_WEIGHT_KG) {
                    // kilograms
                    weight = parseFloat(weight);
                }
                if (weight == NaN) {
                    break;
                }
                // Check if weight exceeds 1000 grams
                // Convert unit to Kg
                if (weight > 999) {
                    props.setItemMetrics({
                        "item_id": props.item.item_id,
                        "quantity": props.itemMetrics.quantity,
                        "weight": gToKg(weight),
                        // set unit to 2(KG)
                        "unit": 2,
                        "name": props.item.name,
                        "price": props.item.price
                    })
                    break;
                }

                props.setItemMetrics({
                    "item_id": props.item.item_id,
                    "quantity": props.itemMetrics.quantity,
                    "weight": weight,
                    "unit": props.itemMetrics.unit,
                    "name": props.item.name,
                    "price": props.item.price
                })
                break;
            default:
                break;
        }
    }

    let changeUnit = (val) => {
        // console.log(val);
        val = parseInt(val);
        if (props.itemMetrics.unit == 2 && props.itemMetrics.weight > 1) {
            return;
        }
        props.setItemMetrics({
            "item_id": props.item.item_id,
            "quantity": props.itemMetrics.quantity,
            "weight": (val == 2) ? gToKg(props.itemMetrics.weight) : parseFloat(props.itemMetrics.weight) * 1000,
            "unit": val,
            "name": props.item.name,
            "price": props.item.price
        });


    }

    let formatMetrics = () => {
        props.setItemMetrics({
            "item_id": props.item.item_id,
            "quantity": item_metrics.quantity,
            "weight": (item_metrics.unit == 1) ? (item_metrics.weight > 1000 ? gToKg(item_metrics.weight) : item_metrics.weight) : (item_metrics.weight),
            "unit": (item_metrics.weight > 1000) ? 2 : 1,
            "name": props.item.name,
            "price": props.item.price
        });
    }

    useEffect(() => {
        console.log("updated metrics-" + props.itemMetrics);
        props.setItemMetrics(item_metrics);
        formatMetrics();
    }, []);

    return (
        <div className="order-item-quantity-selector">
            <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                {/* <h5>Woohoo! You can order in any quantity</h5> */}

                <div className="quantity-selection-controls">


                    <ul>
                        <li className={!props.showWeightSelector && "grid-col-3"}>
                            {/* Quantity Selector */}
                            {!props.showWeightSelector && <div></div>}
                            <div className={!props.showWeightSelector ? "wrapper extended" : "wrapper"} style={{ height: "fit-content" }}>
                                {/* label */}
                                <span className="label">Quantity</span>
                                <span>
                                    <i onClick={() => { increment("quantity") }} className="fas fa-plus"></i>
                                    <input id="quantity" type="text" value={props.itemMetrics.quantity} onChange={(e) => handleCustomMetrics(e.target, "quantity")} />
                                    <i onClick={() => { decrement("quantity") }} className="fas fa-minus"></i>
                                </span>
                            </div>
                            {/* Weight Selector */}
                            {props.showWeightSelector && <div className="wrapper" style={{ height: "fit-content" }}>
                                {/* label */}
                                <span className="label">Weight</span>
                                <span className="label right">Unit</span>
                                <span>
                                    <i onClick={() => { increment("weight") }} className="fas fa-plus"></i>
                                    <input id="weight" type="text" defaultValue={props.itemMetrics.weight} value={props.itemMetrics.weight} onChange={(e) => handleCustomMetrics(e.target, "weight")} />
                                    <i onClick={() => { decrement("weight") }} className="fas fa-minus"></i>
                                    <select value={props.itemMetrics.unit} className="unit-selector" onChange={(e) => { let val = e.target.value; console.log(val); changeUnit(val) }}>
                                        <option value="1">g</option>
                                        <option value="2">Kg</option>
                                    </select>
                                </span>
                            </div>}
                        </li>

                        <li>
                            <div className="add-to-cart-final">


                                {!props.showWeightSelector && !props.is_item_added && <h5 onClick={() => complete_add_to_cart()}><strong>Add {props.itemMetrics.quantity}</strong> to cart</h5>}
                                {props.showWeightSelector && !props.is_item_added && <h5 onClick={() => complete_add_to_cart()}><strong>Add {props.itemMetrics.quantity}</strong> x <strong>{props.itemMetrics.weight}{units[props.itemMetrics.unit]}</strong> to cart</h5>}
                                {!props.showWeightSelector && props.is_item_added && <h5 onClick={() => complete_add_to_cart()}><strong>Update to {props.itemMetrics.quantity}</strong></h5>}
                                {props.showWeightSelector && props.is_item_added && <h5 onClick={() => complete_add_to_cart()}><strong>Update to {props.itemMetrics.quantity}</strong> x <strong>{props.itemMetrics.weight}{units[props.itemMetrics.unit]}</strong></h5>}
                                {props.is_item_added && <h5 style={{ background: "transparent", color: "darkgray", "textTransform": "uppercase", fontWeight: "bold" }} onClick={() => remove_from_cart()}>Remove Item</h5>}
                                {props.is_item_added && <div></div>}
                                {/* <h4><i className="fas fa-inr"></i>&nbsp;100</h4> */}
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>)
}