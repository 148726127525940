// ORDER SERVICE
// API CALLS: v1.0
// Author: Syed Mohammad Talha

import axios from "axios";
import KDResponse from "./KDResponse";
import STATUS, { StatusCode } from "./Status";


const OrderService = {
    "searchProduct": (q) => {
        let status = -1;
        let body = {};

        // check if query string is at least 2 characters
        if (q.length < 2 || q == undefined || q.constructor.name != 'String') {
            status = new StatusCode(500);
        } 
        // make api call if query string fits the criteria
        else { status = new StatusCode(200); }

       
        return KDResponse(body, status);

        // axios.post();


    }
}

export default OrderService;