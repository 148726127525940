

/**
 * ENVIRONMENT--- 0 (PRODUCTION)
 * ENVIRONMENT--- 1 (DEVELOPMENT)
 * 
 * 
 */
const ENVIRONMENT = [
    {
        "name": "PRODUCTION",
        "host": "https://appdata.kiranadigital.com"
        // "host": "https://54.87.218.147:3000"
        // "host": "http://34.207.106.13:3000"
        // "host":"http://52.70.213.53:3000"
    },
    {
        "name": "LOCAL_SERVER",
        "host": "https://localhost:3000"
    },
    {
        "name": "DEVELOPMENT",
        "host": "https://localhost:3000"
    }
]


const baseUrl = `${process.env.NODE_ENV == "development" ? ENVIRONMENT[1].host : ENVIRONMENT[0].host}`
// const baseUrl = "https://192.168.1.100:3000";
// const baseUrl = "https://192.168.10.162:3000";
// const baseUrl = "https://172.16.10.71:3000";

const Params = {
    CENTRE_HOST: baseUrl,
    TOKEN_ALIAS: "ac-t+x",
    SERVICE_BASE_URL: `${baseUrl}/u/api/v1`,
    CENTRAL_SERVICE_URL: `${baseUrl}/api/v1`,
    APPDATA_BASE_URL:`${baseUrl}`,
    AUTH_REGISTER: "/auth/signup",
    AUTH_CHECK_USER: "/auth/check-user",
    // payment endpoints
    PAYU_REDIRECT:"/p/payu/v1/secure/payment/redirect",
    VPA_STATUS:"/p/payu/v1/check-vpa",
    // store endpoints
    STORE_SEARCH: "/data/stores/get-stores/",
    // cart endpoints
    CART_VALIDATE:  "/cart/validate",
    CART_PROCESS: "/cart/process",
    AXIOS_AUTH_HEADERS: (token) => {
        return {
            "headers": {
                "z_string": token


            },

            "withCredentials": true
        }
    },
}

export default Params;