import logo_white_tint_path from "./images/logo-white.png";

export default function SplashScreen(props) {
    return (
        <>
            <div className="splash-screen-container">
                <div className="splash-screen-branding">
                    <div></div>
                    <img src={logo_white_tint_path} />
                    <h4>Your local shopping stop!</h4>
                    <div></div>
                </div>
                {/* <div id="dividerLine" style={{height:"5px", background:"#FFFFFF", width:"50%"}}></div> */}
            </div>
        </>
    )
}