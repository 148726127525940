import { useEffect, useState } from "react";
import upi_icon_svg from "../images/upi-icon.svg";
import credit_cartd_icon_svg from "../images/credit-card-payment-icon.svg";
import cod_icon from "../images/cod.png";
import CommonService from "../common/services";
import { CustomType, OptionType, TypedArray, validateObject } from "../common/utils";

/**
 * 
 * Protected Component
 *
 * props.orderForm
 *  
 */

function Checkout(props) {


    const [orderForm, setOrderForm] = useState(null);
    // when verifying upi id
    const [verify, setVerify] = useState(false);
    const [vpa, setVPA] = useState({ "vpa": "", "status": false });
    const [checking, setChecking] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("0");

    const [totalAmount, setTotalAmount] = useState(null);

    const verifyVPA = () => {
        console.log("verifying");
        var regex = new RegExp(/[a-zA-Z]@[a-zA-Z]/);

        if (!(vpa.vpa.length > 5 && regex.test(vpa.vpa))) return false;
        setChecking(true);
        CommonService.checkIfValidVPA({ "vpa": vpa.vpa }).then(data => {
            setChecking(false);
            if (data && data.vpa_status) {
                setVPA({ 'vpa': vpa.vpa, 'status': data.vpa_status });
            }

        })
    }

    useEffect(() => {
        console.log(vpa);
    }, [vpa]);

    const startUPIPayment = async () => {
        if (!(vpa.vpa && vpa.status)) return false;
        await CommonService.goToPaymentPage({
            orderTransactionId: "66bf6eb2ea4bf8f55e60760e_1723823764699",
            amount: 100,
            cartId: "66bf6eb2ea4bf8f55e60760e",
            paymentType: 1,
            vpa: "atsdfasf@payu.in"

        }).then(content => {
            if (content.startsWith("<form")) {
                window.document.body.innerHTML += content;
                const submitForm = document.querySelector("[data-completepayment]");
                if (submitForm) {
                    submitForm.click();
                }
            }
        });
    }
    const startPaymentFlow = async () => {
        await CommonService.goToPaymentPage({
            orderTransactionId: "66bf6eb2ea4bf8f55e60760e_1723823764699",
            amount: 100,
            cartId: "66bf6eb2ea4bf8f55e60760e",
            paymentType: 2,

        }).then(content => {
            if (content.startsWith("<form")) {
                window.document.body.innerHTML += content;
                const submitForm = document.querySelector("[data-completepayment]");
                if (submitForm) {
                    submitForm.click();
                }
            }
        });
    }

    const startCODFlow = async () => {
        await CommonService.goToPaymentPage({
            orderTransactionId: "66bf6eb2ea4bf8f55e60760e_1723823764699",
            amount: 100,
            cartId: "66bf6eb2ea4bf8f55e60760e",
            paymentType: 0,

        }).then(content => {
            // 
        });
    }



    const selectPaymentMethod = (n) => {
        const ul = document.querySelector("ul");
        const li = Array.prototype.slice.call(ul.childNodes);

        li.forEach(l => {
            l.classList.remove("selected");
        });
        setSelectedPaymentMethod(n.getAttribute("data-pm_"));
        console.log(n.getAttribute("data-pm_"));
        n.classList.add("selected");

    }

    useEffect(() => {

        // payment method items
        const ul = document.querySelector("ul");
        const li = Array.prototype.slice.call(ul.childNodes);

        li.forEach(l => l.addEventListener("click", (e) => {
            selectPaymentMethod(l);
        }))

        const weight = {
            unit: new OptionType("1", "2", "3"),
            value: "number"
        }

        const OrderItem = {
            name: "string",
            unit_price: "number",
            qty: "number",
            total: "number",
            weight: new CustomType(weight)
        }

        const OrderMessage = {
            order_total: "number",
            total_item_qty: "number",
            order: new TypedArray(OrderItem)
        }


        if (validateObject(props.orderForm, OrderMessage)) {
            setOrderForm(props.orderForm);
        }


    }, []);

    useEffect(() => {
        // checking order total amount before setting it
        if (orderForm && orderForm.order_total < 50000 && orderForm.order_total > 0) {
            setTotalAmount(orderForm.order_total);
        }
    }, [orderForm]);

    return (
        <>
            <div style={{ height: "fit-content", maxHeight: "85vh" }} className="overflow-container card-container">
                <div className="card-container">
                    <h3 style={{ fontFamily: "Poppins", fontWeight: "normal" }}>You are just a payment away from your order :)</h3><br />
                    <div className="flex-list-item j-c-se a-i-c p_09em">

                        <h1 style={{ fontFamily: "system-ui" }} className="text-center">
                            {totalAmount && <span>&#8377; {totalAmount}</span>}
                        </h1>
                    </div>
                    <ul>
                        <li data-pm_="1" className="payment-method-li card-container column p_07em">
                            <div onClick={() => setVerify(!verify)} className="payment-method-item" data-paymentmethod="upi">
                                <span>
                                    <img width={40} src={upi_icon_svg} />
                                </span>
                                <p>
                                    Pay using UPI
                                </p>
                                <span>

                                </span>
                            </div>
                            {verify && <div className="card-form p_07em">
                                <div className="field">
                                    {vpa.status && <span style={{ fontWeight: "bold", color: "green", "margin": "5px", }}>Good to go!</span>}
                                    <input style={{ border: vpa.status ? "2px solid green" : "", transition: vpa.status && "none" }} onChange={(e) => setVPA({ "vpa": e.target.value, "status": false })} type="text" placeholder="Please enter your UPI Id" />
                                    {!checking && <button onClick={() => verifyVPA()} className="btn btn-dark">Verify</button>}
                                    {checking && <button onClick={() => { }} className="btn btn-dark">Verifying..</button>}
                                </div>

                            </div>}
                        </li>
                        <li data-pm_="2" className="payment-method-li card-container row p_07em">
                            <div className="payment-method-item" data-paymentmethod="card">
                                <span>
                                    <img width={40} src={credit_cartd_icon_svg} />
                                </span>
                                <p>
                                    Pay using Credit/Debit Card
                                </p>
                                <span>

                                </span>
                            </div>
                        </li>
                        <li data-pm_="3" className="payment-method-li card-container row p_07em">
                            <div className="payment-method-item" data-paymentmethod="cod">
                                <span>
                                    <img width={40} src={cod_icon} />
                                </span>
                                <p>
                                    Cash on Delivery
                                </p>
                                <span>

                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                {selectedPaymentMethod == "0" && <button onClick={() => { }} className="btn btn-dark" >Pay now </button>}
                {selectedPaymentMethod == "1" && <button onClick={() => { startUPIPayment(); }} className="btn btn-dark" >Pay now </button>}
                {selectedPaymentMethod == "2" && <button onClick={() => { startPaymentFlow(); }} className="btn btn-dark" >Pay now </button>}
            </div >

        </>
    )

}

export default Checkout;