import { useEffect, useState } from "react";
import AuthService from "./services";
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";


export default function UserSignUp(props) {

    // user details
    const [name, setName] = useState("");
    const [house_no, setHouseNo] = useState("");
    const [neighbourhood, setNeighbourhood] = useState("l1");
    const [city, setCity] = useState("c1");
    const [landmark, setLandmark] = useState("");

    // user details end

    const neighbourhoods = ["l1"];
    const cities = ["c1"];

    const [processing, set_processing] = useState(false);
    const [prepared, setPrepared] = useState(false);


    const navigate = useNavigate();
    const validateData = () => {
        if ((name.length > 4) || (house_no.length > 2) || (neighbourhoods.findIndex(v => v == neighbourhood) != -1) || (cities.findIndex(v => v == city) != -1)) {
            setPrepared(true);
            return true;
        }
        props.set_toast_message("Please check the values");

        return false;
    }

    useEffect(() => {
        if (prepared) {
            set_processing(true);
            AuthService.registerUser({
                "name": name,
                "house_number": house_no,
                "neighbourhood_id": neighbourhood,
                "landmark": landmark,
                "city_id": city
            }).then(data => {
                set_processing(false);
                setPrepared(false);
                console.log(data);
                if (data && data.code == "USER_CREATED") {
                    
                    // add redirect to shop
                }
                
            }).catch(err => {
                console.log(err);
                set_processing(false);
                setPrepared(false);
                props.set_toast_message("An error occurred");
            });
        }
    }, [prepared]);

    props.set_login_screen(true);
    return (<>

        <div className="container">
            <form className="card-container card-form">
                <br />
                <h3 style={{ textAlign: "center", color: "green" }} className="main-heading">Just a few more things..</h3><br />
                <div className="field">
                    <label>Your name</label>
                    <p>This name will be used to identify your address and your order.</p>
                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="your name" />
                </div>
                <div className="field">
                    <label>Your Flat number/house number</label>
                    <p>This name will be used to identify your address and your order.</p>
                    <input value={house_no} onChange={(e) => setHouseNo(e.target.value)} type="text" placeholder="house number" />
                </div>
                <div className="field">
                    <label>City</label>
                    <p>City</p>
                    <select value={"c1"} onChange={(e) => setCity(e.target.value)} className="select_button_field">
                        <option value={"c1"} selected>New Delhi</option>
                    </select>
                </div>
                <div className="field">
                    <label>Locality</label>
                    <p>Neighbourhood where you live</p>
                    <select value={"l1"} onChange={(e) => setNeighbourhood(e.target.value)} className="select_button_field">
                        <option value={"l1"} selected>Locality</option>
                    </select>
                </div>
                <div className="field">
                    <label>Landmark</label>
                    <p>Landmark or anything that will help us locate you better</p>
                    <input value={landmark} onChange={(e) => setLandmark(e.target.value)} type="text" placeholder="landmark/remarks" />
                </div>
                {!processing && <button onClick={(e) => { e.preventDefault(); validateData(); }} className="btn-dark">Ready to order</button>}
                {processing && <button style={{fontWeight:"bolder"}} className="btn-dark" onClick={(e)=>e.preventDefault()}>Getting ready to order...</button>}
            </form>
        </div>

    </>)

}