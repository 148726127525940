import axios from "axios"
import Params from "../constants"
import { authHeaders } from "../auth/services";


const CommonService = {};

CommonService.getStoresForCurrentLocation = async (body) => {
    return axios.post(Params.CENTRAL_SERVICE_URL + Params.STORE_SEARCH + '?nocache=' + new Date().getTime(), body, authHeaders).then(res => res.data).catch(err => err.response);
}

CommonService.checkIfValidVPA = async (body) => {
    return axios.post(Params.APPDATA_BASE_URL + Params.VPA_STATUS + '?nocache=' + new Date().getTime(), body).then(res => res.data).catch(err => err.response);
}

CommonService.goToPaymentPage = async (body) => {
    
    var headers = await authHeaders();
    headers["params"] = body;
    console.log("headers:", headers);

    return axios.get(Params.APPDATA_BASE_URL + Params.PAYU_REDIRECT + '?nocache=' + new Date().getTime(), headers).then(res => res.data).catch(err => err.response);
}

CommonService.getCartTotals = async (body) => {

    var headers = await authHeaders();

    return axios.post(Params.SERVICE_BASE_URL + Params.CART_VALIDATE, body, headers).then(res => res.data).catch(err => err.response);

}

CommonService.processCart = async(body)=>{
    
    var headers = await authHeaders();

    return axios.post(Params.SERVICE_BASE_URL + Params.CART_PROCESS, body, headers).then(res => res.data).catch(err => err.response);

}



export default CommonService;