import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import Params from './constants';


// Authorization middleware

const AuthorizationRequired = ({ children }) => {

    // Check if auth token exists
    function tokenExists() {
        return localStorage.getItem(Params.TOKEN_ALIAS) != undefined && localStorage.getItem(Params.TOKEN_ALIAS) != null;
    }

    // Get router location
    let location = useLocation();

    // Redirect to login page if not authorized
    if (tokenExists()) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children

};

export default AuthorizationRequired;